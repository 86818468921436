import React from 'react'
import { Form, Formik } from 'formik'
import Button from '../../../components/common/Button'
import Input from '../../../components/common/Input'
import { required } from '../../../utils/validators'

export default function SecretCodeForm({ onCancel, onSubmit, initialValues, loading }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{ code: '', ...initialValues }}>
            <Form>
                <Input name="code" label="Код" validate={required} />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Ёпиш" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сақлаш" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
