import React from 'react'
import { usePostRequest } from '../../../hooks/request'
import { useMessage } from '../../../hooks/message'
import SecretCodeForm from './SecretCodeForm'

export default function SecretCodeCreate({ onSuccess, onCancel }) {
    const brandCreate = usePostRequest({ url: '/main/secret-code' })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandCreate.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Қўшиш</b>
            </h1>

            <SecretCodeForm loading={brandCreate.loading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}
