import React from 'react'
import { usePutRequest } from '../../../hooks/request'
import UsersForm from './SecretCodeForm'

export default function SecretCodeUpdate({ onCancel, onSuccess, item }) {
    const brandUpdate = usePutRequest({ url: '/main/secret-code/{id}'.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await brandUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Изменить</b>
            </h1>
            <UsersForm loading={brandUpdate.loading} initialValues={item} onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
