/* eslint-disable */

import * as XLSX from 'xlsx'
import React, { useState } from 'react'
import { useLoad } from '../hooks/request'
import { INCOME_ITEM_LIST } from '../urls'
import Table from './common/Table'
import IncomeDetailItem from './IncomeDetailItem'
import IncomeDetailCreate from './IncomeDetailCreate'
import DateFilter from './common/DateFilter'
import { useModal } from '../hooks/modal'
import Button from './common/Button'
import { useQueryParams } from '../hooks/queryString'

export default function IncomeDetailTable({ incomeId, category }) {
    const [page, setPage] = useState(1)
    const params = useQueryParams()
    const expenseReport = useLoad({ url: INCOME_ITEM_LIST, params: { income: incomeId, ...params } }, [params])
    const [showCreateModal, hideCreateModal] = useModal(<IncomeDetailCreate onCancel={() => hideCreateModal()} expenseReport={expenseReport} incomeId={incomeId} />)

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
        }).format(value).replace(/,/g, ' ');
    }

    const exportToExcel = (response) => {
        const headers = [
            { text: 'ID', key: 'id' },
            { text: 'Маҳсулот номи', key: 'name' },
            { text: 'Маҳсулот сони', key: 'count' },
            { text: 'Маҳсулот нархи', key: 'price' },
            { text: 'Жами', key: 'total' },
            { text: 'Сана', key: 'createdAt' },
        ]

        console.log(response, 'sa')

        const data = response?.results?.map((item) => ({ id: item.id, name: item.name, count: item.count, price: item.price, total: item.total, createdAt: new Date(item.createdAt).toLocaleDateString('en-GB') }))

        const totalRow = { id: '', name: '', count: '', total: 'ЖАМИ', price: response?.totalPrice, createdAt: '' }

        const rows = [headers?.map((header) => header?.text), ...data?.map((item) => headers?.map((header) => item[header?.key])), ['', '', '', '', '', ''], Object.values(totalRow)]

        const ws = XLSX.utils.aoa_to_sheet(rows)

        const range = XLSX.utils.decode_range(ws['!ref'])
        const borderStyle = {
            top: { style: 'medium', color: { rgb: '000000' } },
            bottom: { style: 'medium', color: { rgb: '000000' } },
            left: { style: 'medium', color: { rgb: '000000' } },
            right: { style: 'medium', color: { rgb: '000000' } },
        }

        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: R, c: C })
                if (!ws[cellAddress]) ws[cellAddress] = { v: '' }
                ws[cellAddress].s = { border: borderStyle }
            }
        }

        const colWidths = headers.map((header, index) => {
            const maxLength = Math.max(...rows.map((row) => (row[index] ? row[index].toString().length : 0)))
            return { wch: maxLength + 2 }
        })
        ws['!cols'] = colWidths

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Молия Маълумотлари')

        XLSX.writeFile(wb, 'МолияМаълумотлари.xlsx')
    }

    return (
        <div>
            <div className="is-flex is-justify-content-space-between mb-3 is-align-content-center">
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                    <h2 style={{ fontSize: 22, fontWeight: 'bold', gap: 10 }}>{category.name}</h2>
                </div>

                <div className="column is-narrow is-flex">
                    <Button text="Excel" className="is-pulled-right is-success is-outlined mr-2" onClick={() => exportToExcel(expenseReport?.response)} />
                    <DateFilter className="mr-2" name="start_date" />
                    <DateFilter className="mr-2" name="end_date" />
                    <Button onClick={showCreateModal} text="Қўшиш" className="is-pulled-right is-link is-outlined" />
                </div>
            </div>
            <div className="mt-4">
                <Table
                    loading={expenseReport.loading}
                    items={expenseReport?.response ? expenseReport?.response?.results : []}
                    columns={{ index: '№', name: 'Маҳсулот номи', count: 'Маҳсулот сони', amount: 'Маҳсулот нархи', total: 'Жами', date: 'Сана', buttons: '' }}
                    totalCount={expenseReport.response ? expenseReport.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <IncomeDetailItem expenseReport={expenseReport} index={index} key={item.id} item={item} />}
                />
            </div>
            <div style={{ gap: 10 }} className="is-flex is-justify-content-end mt-5">
                <div className="is-pulled-right mt-2 is-size-6 is-bold tag is-light" style={{ fontWeight: 'bold' }}>
                    Жами: {format(expenseReport.response ? expenseReport.response.totalCount : 0)}
                </div>
                <div className="is-pulled-right mt-2 is-size-6 is-bold tag is-light" style={{ fontWeight: 'bold' }}>
                    Баланс: {format(expenseReport.response ? expenseReport.response.totalPrice : 0)} Сум
                </div>
            </div>

        </div>
    )
}
