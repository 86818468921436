import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import SettingsTabs from '../../../components/settings/SettingsTabs'
import Card from '../../../components/common/Card'
import Button from '../../../components/common/Button'
import Table from '../../../components/common/Table'
import { useModal } from '../../../hooks/modal'
import { useLoad } from '../../../hooks/request'
import SecretCodeCreate from './SecretCodeCreate'
import { useQueryParams } from '../../../hooks/queryString'
import SecretCodeItem from './SecretCodeItem'

export default function SecretCode() {
    const [page, setPage] = useState(1)
    const params = useQueryParams()

    const category = useLoad({ url: '/main/secret-code', params: { page, ...params, size: 1000 } }, [page, params])

    const [showCreateModal, hideCreateModal] = useModal(
        <SecretCodeCreate
            onSuccess={() => {
                hideCreateModal()
                category.request()
            }}
            onCancel={() => hideCreateModal()}
        />,
    )

    const categories = category.response ? category.response : []

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gap: '1rem' }}>
                <Card>
                    <div className="columns">
                        <div className="is-size-4 column">Код</div>

                        <div className="column is-narrow">
                            <Button onClick={showCreateModal} text="Қўшиш" icon="add-outline"
                                className="is-pulled-right is-link is-outlined" />
                        </div>
                    </div>

                    <Table
                        loading={categories.loading}
                        items={categories}
                        columns={{
                            index: '№',
                            name: 'Код',
                            buttons: ''
                        }}
                        totalCount={categories.response ? categories.response.count : 0}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => (
                            <SecretCodeItem index={index} category={category} item={item}
                                key={item.id}
                            />
                        )}
                    />
                </Card>
                <br />
            </div>
        </Layout>
    )
}
